body{
    font-family: 'Open Sans', sans-serif;
}
*:hover{
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
}
section{
    float:left;
    width:100%;
    background: #fff;  /* fallback for old browsers */
    padding:30px 0;
}



/*Profile Card 5*/
.profile-card-5{
    border: 1px solid #ccc; /* Add border with desired color */
    border-radius: 5px; /* Optional: Add border radius for rounded corners */
    margin-top:20px;
}
.profile-card-5 .btn{
    border-radius:2px;
    text-transform:uppercase;
    font-size:12px;
    padding:7px 20px;
}
.profile-card-5 .card-img-block {
    width: 91%;
    margin: 0 auto;
    position: relative;
    top: -20px;
    
}
.profile-card-5 .card-img-block img{
    border-radius:5px;
    box-shadow:0 0 10px rgba(0,0,0,0.63);
}
.profile-card-5 h5{
    color:#4E5E30;
    font-weight:600;
}
.profile-card-5 p{
    font-size:14px;
    font-weight:300;
}
.profile-card-5 .btn-primary{
    background-color:#4E5E30;
    border-color:#4E5E30;
}
.abc {
    width: 90%;
    margin: 20px auto; /* Adjust margin as needed */
    padding: 20px; /* Adjust padding as needed */
}
.tile {
    background-color: white;
    width: 100%; /* You can change the size however you like. */
    /* height: 15em; */
    margin: 1em;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4);
  }
  
  .tile-img {
    background-size: cover;
    background-position: center center;
    background-repeat: none;
    width: 15em;

    height: inherit;
    float: left;
  }
  .grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Adjust column size as needed */
    gap: 20px; /* Adjust gap between grid items */
  }
  
  .tile-info {
    height: inherit;
    padding: 1em;
    
  }
  
  /* These are optional changes I make to my css. */
  
  * {
    /* margin: 0; */
    padding: 0;
    box-sizing: border-box;
  }
  
  p {
    font-family: sans-serif;
    color: #777;
  }
  
  /* You can ignore anything below this. It does not contribute to the codepen in anyway, It's just so I can center the codepen when it displays and make it look pretty :D */
  
  .popup {
    position: fixed;
    
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-content {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
  }
  
  .popup-content p {
    margin: 0 0 20px;
  }
  
  .popup-content button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .popup-content button:hover {
    background-color: #0056b3;
  }
  

.card-profile .card-avatar {
    width: 130px;
    max-width: 130px;
    max-height: 130px;
    margin: -50px auto 0;
    border-radius: 50%;
    overflow: hidden;
    padding: 0;
    box-shadow: 0 16px 38px -12px rgba(0,0,0,.56), 0 4px 25px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(0,0,0,.2);
}
.cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /*Adjust grid columns*/
    gap: 2rem;
    margin: 4rem 5vw;
    
    padding: 20px;
    /* margin-top: 8%;   */
    /* height: 80vh; Set height to enable scrolling */
    list-style-type: none;
    overflow-y: auto; /* Enable vertical scrolling */
    /* overflow-y: auto; Enable vertical scrolling */
    /* height: 99vh; Occupy full viewport height */
    /* width: 100%; Occupy full viewport width */
    /* width :100vw */
  }

  .button-6 {
    align-items: center;
    background-color: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: .25rem;
    box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    display: inline-flex;
    font-family: system-ui,-apple-system,system-ui,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 16px;
    font-weight: 600;
    justify-content: center;
    line-height: 1.25;
    margin: 2%;
    min-height: 3rem;
    padding: calc(.875rem - 1px) calc(1.5rem - 1px);
    position: relative;
    text-decoration: none;
    
    transition: all 250ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    width: auto;
  }
  
  .button-6:hover,
  .button-6:focus {
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    color: rgba(0, 0, 0, 0.65);
  }
  
  .button-6:hover {
    transform: translateY(-1px);
  }
  
  .button-6:active {
    background-color: #F0F0F1;
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
    color: rgba(0, 0, 0, 0.65);
    transform: translateY(0);
  }
.card-profile .card-avatar img{
    width: 100%;
}
.card-body{
    padding: 1rem 1rem;

}
.card-profile .card-avatar+.card-body{
    align-items: center;
    margin-top: 15px;
    
}
.bids-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /*Adjust grid columns*/
    grid-gap: 20px; /* Adjust the gap between grid items */
  }
  .profile-card-5 {
  /* Your card styles here */
  /* width: 300px;    height: 400px;  */
  overflow: hidden; /* Prevent content overflow */
}
.card-title{
    align-self: center;
    align-items: center;

}
.profile-card-5 .card-img-block img {
    display: grid;
    max-width: 100%; /* Limit the maximum width of the image */
    max-height: 100%; /* Limit the maximum height of the image */
    width: auto; /* Ensure the image maintains its aspect ratio */
    height: auto; /* Ensure the image maintains its aspect ratio */
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.63);
  max-width: 100%; /* Ensure the image doesn't exceed the card width */
  height: auto; /* Maintain aspect ratio */
}