/* src/components/IntroSlider.module.css */
.sliderContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .slick-slide {
    text-align: center;
    padding: 40px;
  }
  
  .closeButton {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #ff9900;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
  }
  