/* AddGroceryPage.css */


.container {
  max-width: 600px;
  padding: 6%;
  margin: 20px auto; /* Center the container */
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  z-index: 9999;
}

.popup.success {
  background-color: rgba(60, 179, 113, 0.8); /* Success color */
}

.popup.error {
  background-color: rgba(255, 0, 0, 0.8); /* Error color */
}

.form {
  width: 100%;
  background: #e6e6e6;
  border-radius: 8px;
  box-shadow: 0 0 40px -10px #000;
  padding: 20px 30px;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #78788c;
}

label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  color: #5a5a5a;
}

input[type="text"],
input[type="number"],
input[type="date"],
input[type="email"] {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button[type="submit"] {
  width: 100%;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #404142;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 20px; /* Add space between button and input fields */
}

button[type="submit"]:hover {
  background-color: #0056b3;
}
